import React from 'react';
import { Container, Content } from '../../components/Home/v2/StyledComponents';
import MenuBar from '../../components/Home/MenuBar';
import Press from '../../components/Home/Press';
import Footer from '../../components/Home/Footer';
import Layout from '../../layouts/index';

class PressPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    return (
      <Layout routeSlug="PressPage">
        <Container>
          <MenuBar />
          <Content><Press /></Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default PressPage;
